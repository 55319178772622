.resumeInfo {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 0px 0px 25px;
    box-shadow: 0px 4px 11px -4px #949494;
}

.resumeInfo hr {
    width: 100%;
    height: 1px;
    border: 0;
    background: linear-gradient(45deg, transparent, #bfbfbf);
}