.dashboardItem {
    padding: 4px 20px;
}

.dashboardItem a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #686868;
    padding: 9px 10px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.dashboardItem span {
    display: inline-flex;
    width: 20px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 15px;
}

.dashboardItem a:hover {
    background:#f3f5f4;
}
.dashboardItem a.active {
    background: #f3f5f4;
    color: #363636;
}