.image {
    width: 70%;
    height: 160px;
    overflow: hidden;
    border-radius: 5px;
    margin: 0px 5px 30px;
    position: relative;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.image .number {
    position: absolute;
    top: 2px;
    left: 4px;
    width: 25px;
    height: 25px;
    background: rgba(0,0,0,0.5);
    color: #efefef;
    padding: 2px 0px 0px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.image .title {
    position: absolute;
    bottom: -26px;
    left: 0;
    width: 100%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0px 0px;
    font-size: 15px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.image:hover .title {bottom: 0;}