.div-skills {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 30px 10px 0px;
    box-shadow: 0px 4px 11px -4px #949494;
}

.div-skills .skillbar {
    background: transparent!important;
    text-align: left;
    margin-bottom: 30px;
}

.div-skills .skillbar .skillbar-title {
    font-size: 10px;
    width: 92px;
}

.div-skills .skillbar .skillbar-title span {background: transparent;}

.div-skills .skillbar .skillbar-bar {left: 75px;}