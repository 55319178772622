.message {
    display: flex;
    justify-content: center;
    align-items: center;
}

.message span {
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
}

.message.danger span {background: #ff4545;}
.messange.success span{background: #388e3c;}
