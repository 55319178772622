
.dashboardItems .btn-exit button {
    padding: 6px 20px;
    border: none;
    outline: none;
    background: #077de2;
    color: #fff;
    border-radius: 5px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.dashboardItems .btn-exit button:hover {background: #0068c1;}