.dashboardImage .img-info {
    background: #fff;
    margin: 0px 0px 15px;
    padding: 10px 20px;
    color: #929292;
    justify-content: space-between;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 4px 15px -8px #616161;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.dashboardImage .img-info label {
    margin: 0px 0px 0px 5px;
    color: #4c4c4c;
    font-size: 15px;
}

.dashboardImage .img-info .smallImg {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 4px;
}

.dashboardImage .img-info .smallImg img {width: 100%;height: 100%;object-fit: cover;}

.dashboardImage .img-info .number {width: 17px;}

.dashboardImage .img-info .ManagementInfo {
    flex-basis: 100%;
    text-align: center;
    padding-top: 20px;
}

