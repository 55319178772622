.div-card {
    display: flex;
    flex-direction: row;
    background: #fff;
    border: 1px solid #e4e4e4;
    margin: 0 0 15px 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.div-card .card-icon {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #f5f5f5;
}

.div-card .card-info {
    min-width: 60px;
}

.div-card .card-info p {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    color: #4c4c4c;
    font-size: 15px;
    font-weight: 500;
}

.div-card.cardRed .card-icon{background: rgb(245,67,55);}
.div-card.cardOrange .card-icon{background: rgb(255,152,3);}
.div-card.cardBlue .card-icon{background: rgb(36,149,245);}