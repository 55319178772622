.navbar-items {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ;
    border-top: 1px solid ;
    border-image: linear-gradient(90deg , transparent , #3333335c ,transparent) 1;
}

.navbar-items .showNavItems {height: 100%;display: none;}

.navbar-items .showMenuIcon span {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
}

.navbar-items .showMenuIcon span .bar {
    width: 100%;
    height: 2px;
    background: #333;
    position: relative;
}

.navbar-items .showMenuIcon span .bar::after,
.navbar-items .showMenuIcon span .bar::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transform-origin: center;
}

.navbar-items .showMenuIcon span .bar::after {top: 10px;}
.navbar-items .showMenuIcon span .bar::before {top: -10px;}

.navbar-items .showMenuIcon.active span .bar {background: transparent;}
.navbar-items .showMenuIcon.active span .bar::after {
    top: 0;
    transform:rotate(45deg) ;
    -webkit-transform:rotate(45deg) ;
    -moz-transform:rotate(45deg) ;
    -ms-transform:rotate(45deg) ;
    -o-transform:rotate(45deg) ;
}
.navbar-items .showMenuIcon.active span .bar::before {
    top: 0;
    transform:rotate(-45deg) ;
    -webkit-transform:rotate(-45deg) ;
    -moz-transform:rotate(-45deg) ;
    -ms-transform:rotate(-45deg) ;
    -o-transform:rotate(-45deg) ;
}
