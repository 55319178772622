.addWorkSample label {
    width: 100%;
    font-size: 17px;
}
.addWorkSample input {
    margin: 0px 0px 15px 0px;
    width: 95%;
    padding: 3px 10px 0px;
    text-align: center;
    font-size: 14px;
    outline: none;
    color: #333;
}