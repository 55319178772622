

@media (min-width: 576px) { 
    .dashboardImage .img-info .ManagementInfo {flex-basis: auto;padding-top: 0;}
    .dashboardWorkSpace .workSample-info .ManagementInfo {flex-basis: auto;padding-top: 0px;}
    .modal {width: 60%;}
    .banner .typed {font-size: 35px;}
    .images{justify-content: space-between;}
    .image {width: 230px;}
    .bigImage{width: 550px;height: 500px;}
    .resume .title-about{font-size: 15px;}
    .footer .ways-contact span {width: 150px;}
    .footer .ways-contact p {font-size: 16px;}
    .socialMedia {font-size: 16px;}
    .socialMedia a span {font-size: 28px;}
    .footer .copyRight p {font-size: 16px;}
}

@media (min-width: 768px) { 

    .div-card {margin: 0px 0px 15px 20px;}
    .dashboardImage .img-info .ManagementInfo {flex-basis: auto;padding-top: 0;}
    .dashboardImage .img-info .smallImg {width: 60px;height: 60px;}
    .banner {height: 600px;}
    .banner .typed {font-size: 45px;}
    .navbar-items .showNavItems {display: block;}
    .navbar-items .showMenuIcon {display: none;}
    .menu-responsive {display: none;}
    .images{justify-content: center;}
    .image {width: 250px;}
    .div-skills .skillbar .skillbar-title {font-size: 13px;width: 110px;}
    .div-skills .skillbar .skillbar-bar {left: 95px;}
    .scrollTo {padding: 8px;}
}


@media (min-width: 992px) { 

    .dashboardWorkSpace .workSample-info {font-size: 14px;}
    .modal {width: 50%;}
    .banner {height: 650px;}
    .sectionAbout {flex-direction: row;text-align: right;width: auto; bottom: -45px;}
    .sectionAbout p {font-size: 17px;}
    .sectionAbout .picFace {order: 0;}
    .sectionAbout .picFace img {width: 130px;height: 130px;}
    .bigImage{width: 700px;}
    .scrollTo {padding: 10px;}

}


@media (min-width: 1200px) { 

    .div-card {margin: 0px 0px 15px 40px;}
    .bigImage{width: 1000px;height: 600px;}

}