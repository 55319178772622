.footer {
    height: 250px;
    position: relative;
}

.footer .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer .video::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000008c;
}

.footer .video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer .ways-contact span {
    display: block;
    width: 80px;
    height: 1px;
}

.footer .ways-contact span.right {background: linear-gradient(90deg, #c3c3c38a, transparent);}
.footer .ways-contact span.left {background: linear-gradient(270deg, #c3c3c38a, transparent);}

.footer .ways-contact p {
    color: #d0d0d0;
    text-align: center;
    margin: 0 15px 0;
    font-size: 14px;
}

.footer .copyRight p {
    margin: 25px 0px 0px;
    text-align: center;
    color: #d0d0d0;
    text-transform: uppercase;
    font-size: 12px;
}