.sectionAbout {
    width: 260px;
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: auto;
    position: absolute;
    bottom: -85px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    align-items: center;
    box-shadow: 0px 0px 24px 2px rgba(0,0,0,0.3);
    flex-direction: column;
    text-align: center;
}

.sectionAbout .section {padding: 20px;}

.sectionAbout p {font-size: 14px;margin: 0;}
.sectionAbout p:nth-child(1) {font-size: 22px;font-weight: 500;}
.sectionAbout p:nth-child(n+2) {color: #444;}

.sectionAbout .picFace {order: -1;}

.sectionAbout .picFace img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    margin: auto;
}