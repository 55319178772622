.dashboard {
    min-height: 100vh;
}

.dashboard .sidbar {
    background: #fff;
}
.dashboard .sidbar.dark {
    background: rgb(150, 150, 150);
}

.dashboard .sidbar .title-dashboard {
    background: #077de2;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 25px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin: 0px 0px 15px;
}

.dashboard .content-dashboard {
    background: #f3f5f4;
}

.dashboard .content-dashboard .div-dashboard-title p {border-bottom: 1px solid;
    border-image: linear-gradient(-90deg , #5a5a5a , transparent) 1;
    padding: 0px 0px 5px 0px;
    font-size: 20px;
    font-weight: 500;
    color: #616161;}

.dashboard .content-dashboard .account-info {
    height: 50px;
    box-shadow: 0px 11px 21px -17px #272727;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
}

.dashboard .content-dashboard .account-info .switchTheme input {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 45px;
    height: 21px;
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #e0e0e0;
}

.dashboard .content-dashboard .account-info .switchTheme input::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    background: red;
    height: 21px;
    width: 21px;
    transform: translateY(-50%) scale(1.2);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.dashboard .content-dashboard .account-info .switchTheme input:checked::before {left: 22px;}

.dashboard .content-dashboard .div-content {overflow: hidden;}

.toastOption {text-align: left!important;font-family: vazir!important;}

/* .dashboard .content-dashboard .account-info span {
    width: 40px;
    height: 40px;
    background: red;
    border-radius: 50%;
    margin: 0px 10px 0px;
    overflow: hidden;
}

.dashboard .content-dashboard .account-info span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */
