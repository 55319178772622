.banner {
	position: relative;
	width:100%;
	height: 80vh;
	background:url(../../assets/images/header1.jpg) no-repeat;
	background-size:cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner .cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
}

.banner .particle ,
.banner .particle .particles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.banner .typed {
	font-size: 21px;
    font-weight: 700;
    color: rgb(228, 228, 228);
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    direction: ltr;
    letter-spacing: 3px;
}