.button {
    padding: 7px 20px;
    border-radius: 4px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 15px;
    outline: none;
}
.button:focus {outline: none;}

.button a {
    color: #fff;
}

.button.primary {
    background:linear-gradient(180deg, #6baddc, #0f6bbb);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.button.primary:hover {background:linear-gradient(180deg, #0f6bbb , #6baddc);}