.scrollTo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #bb2f2f;
    color: #fff;
    display: flex;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
}

.scrollTo:hover {
    animation: scroll 0.5s ease-in-out infinite;
    -webkit-animation: scroll 0.5s ease-in-out infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
}
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
}
}