.modal {
    width: 85%;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: inherit;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    -webkit-transform: translate(-50% ,-50%);
    -moz-transform: translate(-50% ,-50%);
    -ms-transform: translate(-50% ,-50%);
    -o-transform: translate(-50% ,-50%);
    padding: 40px 0px;
    display: block;
}

.modal .iconClose {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #f72727;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 0px 0px 1px 0px;
    color: #fff;
    cursor: pointer;
}