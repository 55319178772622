.bigImage {
    position: fixed;
    width: 80vw;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    z-index: 100;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
 .bigImage img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
 }

.bigImage .closeIcon {
    position: absolute;
    top: -14px;
    right: -20px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
    background: #da2e2e;
    color: #dedede;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
} 

.bigImage .title {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px 20px 8px;
    color: #fff;
}