.workSample .card {
    overflow: hidden;
    border-bottom: 3px solid rgba(0, 0, 0, 0.125);
    margin: 0px 0px 20px 0px;
}

.workSample .card .pic-work {
    width: 100%;
    height: 240px;
    overflow: hidden;
    background: #000;
}

.workSample .card .pic-work img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.workSample .card .title-work {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workSample .card:hover {
    box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.125);
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
}

.workSample .card:hover .pic-work img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    opacity: 0.8;
}

.workSample .card .link-work {
    border-top:1px solid ;
    border-image: linear-gradient(90deg , transparent , #3333335c ,transparent) 1;
    padding: 10px 0px;
}

.workSample .card .link-work .type span {
    font-size: 15px;
    font-weight: 400;
    color: #7b7b7b;
    border: 1px solid #a9a9a9;
    border-radius: 3px;
    padding: 2px 4px 0px;
}




