.socialMedia {
    text-align: center;
    margin: 0px 10px;
    font-size: 13px;
}

.socialMedia a {
    display: block;
    color: #dadada;
}

.socialMedia a span {
    font-size: 24px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.socialMedia a:hover.Instagram span {color: #ce373b;}
.socialMedia a:hover.Telegram span {color: #2ba0d9;}
.socialMedia a:hover.Whatsapp span {color: #41d259;}