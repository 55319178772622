.navItem {
    margin: 0px 12px;
    width: 130px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItem a {
    color: rgb(88,88,88);
    padding: 4px 20px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.navItem a:hover ,
.navItem a.active {color: red;text-decoration: none;}