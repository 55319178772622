.workSamples .searchWorkSample label{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #5f5e5e;
    margin-left: 10px;
}
.workSamples .searchWorkSample input{
    font-size: 14px;
    padding: 4px 7px 0px;
    outline:steelblue;
    color: #5f5e5e;
    direction: ltr;
}

.workSamples .searchWorkSample input:focus {
    border: 1px solid #0095ff;
}