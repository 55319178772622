.admin {
    position: relative;
    width: 100%;
    height: 100vh;    
}
.admin::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../assets/images/bg-login.jpg)no-repeat center center;
    background-size: cover;
    filter: blur(15px);
    -webkit-filter: blur(15px);
}

.admin .div-login {
    background: rgba(0,0,0,0.5);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #bfbfbf;
}

.admin .div-login input {
    direction: ltr;
    padding: 2px 7px;
    color: #333;
    outline: none;
    margin-bottom: 20px;
}

.admin .toastOption {text-align: left;font-family: vazir;} 