@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Vazir-Black-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-Black-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-Black-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-Black-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-Black-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}
@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/Vazir-Bold-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-Bold-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-Bold-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-Bold-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-Bold-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Vazir-Medium-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-Medium-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-Medium-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-Medium-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-Medium-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}


@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Vazir-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Vazir-Light-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-Light-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-Light-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-Light-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-Light-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Vazir-Thin-FD.eot'); /* IE9 Compat Modes */
  src: local('vazir'), local('vazir'),
       url('./assets/fonts/Vazir-Thin-FD.eot') format('eot'), /* IE6-IE8 */
       url('./assets/fonts/Vazir-Thin-FD.ttf') format('ttf'), /* Super Modern Browsers */
       url('./assets/fonts/Vazir-Thin-FD.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Vazir-Thin-FD.woff2') format('woff2'), /* Safari, Android, iOS */
}

body {
  margin: 0;
  font-family: 'vazir';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.fw-100{font-weight: 100;}
.fw-200{font-weight: 200;}
.fw-300{font-weight: 300;}
.fw-400{font-weight: 400;}
.fw-500{font-weight: 500;}
.fw-600{font-weight: 600;}
.fw-700{font-weight: 700;}
.fw-800{font-weight: 800;}
.fw-900{font-weight: 900;}
.fs-0_1{font-size: 0.1rem;}
.fs-0_2{font-size: 0.2rem;}
.fs-0_3{font-size: 0.3rem;}
.fs-0_4{font-size: 0.4rem;}
.fs-0_5{font-size: 0.5rem;}
.fs-0_6{font-size: 0.6rem;}
.fs-0_7{font-size: 0.7rem;}
.fs-0_8{font-size: 0.8rem;}
.fs-0_9{font-size: 0.9rem;}
.fs-1{font-size: 1rem;}
.fs-1_1{font-size: 1.1rem;}
.fs-1_2{font-size: 1.2rem;}
.fs-1_3{font-size: 1.3rem;}
.fs-1_4{font-size: 1.4rem;}
.fs-1_5{font-size: 1.5rem;}
.fs-1_6{font-size: 1.6rem;}
.fs-1_7{font-size: 1.7rem;}
.fs-1_8{font-size: 1.8rem;}
.fs-1_9{font-size: 1.9rem;}
.fs-2{font-size: 2rem;}
.fs-2_1{font-size: 2.1rem;}
.fs-2_2{font-size: 2.2rem;}
.fs-2_3{font-size: 2.3rem;}
.fs-2_4{font-size: 2.4rem;}
.fs-2_5{font-size: 2.5rem;}
.fs-2_6{font-size: 2.6rem;}
.fs-2_7{font-size: 2.7rem;}
.fs-2_8{font-size: 2.8rem;}
.fs-2_9{font-size: 2.9rem;}
.fs-3{font-size: 3rem;}
.fs-3_1{font-size: 3.1rem;}
.fs-3_2{font-size: 3.2rem;}
.fs-3_3{font-size: 3.3rem;}
.fs-3_4{font-size: 3.4rem;}
.fs-3_5{font-size: 3.5rem;}
.fs-3_6{font-size: 3.6rem;}
.fs-3_7{font-size: 3.7rem;}
.fs-3_8{font-size: 3.8rem;}
.fs-3_9{font-size: 3.9rem;}
.fs-4{font-size: 4rem;}

a , a:hover{text-decoration:none}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
