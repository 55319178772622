.dashboardWorkSpace .workSample-info {
    background: #fff;
    padding: 10px 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 4px 15px -8px #616161;
    font-size: 12px;
    font-weight: 500;
    color: #7d7d7d;
}

.dashboardWorkSpace .workSample-info .number {
    width: 22px;
    height: 22px;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 2px 0px 0px;
    font-size: 12px;
}

.dashboardWorkSpace .workSample-info .ManagementInfo {
    flex-basis: 100%;
    text-align: center;
    padding-top: 20px;
}