.menu {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #c1c1c1;
    border-radius: 10px;
    margin: 10px auto 0;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transform-origin: top;
    height: 0;
}

.menu.openMenu {
    height: 120px;
}
.menu .navItem { opacity: 0; transition:all 0.2s ; -webkit-transition:all 0.2s ; -moz-transition:all 0.2s ; -ms-transition:all 0.2s ; -o-transition:all 0.2s ; }
.menu.openMenu .navItem {opacity: 1;}